
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import useTranslation from "next-translate/useTranslation";
import { Flex, Box, Text, Heading } from "rebass/styled-components";
import Layout from "../components/Layout";
import Logo from "../components/common/Logo";
import Link from "../components/common/Link";

function Custom404() {
  const { t } = useTranslation("error");

  return (
    <Layout
      withTransparentHeader
      immersive
      hideHeader
      title={t("notFoundError")}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        textAlign="center"
        sx={{
          background: "url('/images/patterns/dog-hair.svg')",
          backgroundRepeat: "repeat",
          backgroundSize: "200px"
        }}
      >
        <Box
          bg="pugYellow"
          maxWidth="600px"
          minHeight={["100vh", 0]}
          padding="8em 4em"
        >
          <Flex mb={4} justifyContent="center">
            <Logo
              color="samoyedWhite"
              height="60px"
              width="200px"
              name="logo"
            />
          </Flex>

          <Heading
            as="h1"
            color="white"
            fontSize="40px"
            lineHeight="1.5em"
            mb={4}
          >
            {t("notFoundError")}
          </Heading>

          <Link
            href={{
              pathname: "/search"
            }}
          >
            <Text
              color="white"
              sx={{
                color: "springGreen",
                fontWeight: "bold",
                fontSize: 4,
                textDecoration: "underline"
              }}
            >
              {t("goBack")}
            </Text>
          </Link>
        </Box>
      </Flex>
    </Layout>
  );
}

export default Custom404;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  